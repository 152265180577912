import React, { useRef, useEffect, useState } from "react"
import loadable from "@loadable/component"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "@material-ui/core/Grid"
// import { navigate } from "gatsby"
// import Helmet from "react-helmet"

// import Button from '@material-ui/core/Button';

import gql from "graphql-tag"
import { Query } from "react-apollo"
import { useQuery } from "@apollo/react-hooks"

import useDynamicRefs from "use-dynamic-refs"

import DOMPurify from "dompurify"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

import { CircularProgress } from "@material-ui/core"

import RequestApraisal from "../components/requestApraisal"

import SliderTeam from "../components/SliderTeam"

const axios = require("axios")

const FeaturedProperty = loadable(() =>
  import("../components/featuredProperty")
)

let content = (
  <div style={{ padding: "50%" }}>
    <CircularProgress color="inherit" />
  </div>
)

const APOLLO_QUERY_QUOTES = gql`
  {
    cLIENTQUOTES(first: 5) {
      edges {
        node {
          content
          cLIENT_QUOTEId
        }
      }
    }
  }
`

const APOLLO_HP_ID = gql`
  {
    hompageScrollers {
      edges {
        node {
          homepage_scroller {
            copy
            image1 {
              mediaItemUrl
            }
            image2 {
              mediaItemUrl
            }
            image3 {
              mediaItemUrl
            }
            image4 {
              mediaItemUrl
            }
            image5 {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const sliderRef = useRef(null)
  const overlayRight = useRef(null)

  const [getRefQuote, setRefQuote] = useDynamicRefs()
  const [getRefButton, setRefButton] = useDynamicRefs()
  const [showAppraisal, setShowAppraisal] = useState(true)

  const [houseData, setHouseData] = useState([])
  const [housePageCount, setHousePageCount] = useState(0)
  const [gallery, setGallery] = useState([])
  const [galleryCopy, setGalleryCopy] = useState(null)

  const sanitizer = DOMPurify.sanitize

  useEffect(() => {
    const url =
      "https://db.brownandkay-ejfw.temp-dns.com/wp-json/custom/v2/housesforsale/24"
    axios
      .get(url, {
        headers: {},
      })
      .then(function(response) {
        setHouseData(response?.data)
      })
      .catch(function(error) {
        console.log("error" + error)
      })
  }, [])

  function sliderHeaderSwiped(event) {
    if (sliderRef && sliderRef?.current) {
    }
  }

  const { data } = useQuery(APOLLO_HP_ID, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      let galleryHP = []

      if (data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.copy) {
        setGalleryCopy(
          data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.copy
        )
      }

      if (data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.image1) {
        galleryHP.push(
          data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.image1
            ?.mediaItemUrl
        )
      }
      if (data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.image2) {
        galleryHP.push(
          data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.image2
            ?.mediaItemUrl
        )
      }
      if (data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.image3) {
        galleryHP.push(
          data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.image3
            ?.mediaItemUrl
        )
      }
      if (data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.image4) {
        galleryHP.push(
          data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.image4
            ?.mediaItemUrl
        )
      }
      if (data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.image5) {
        galleryHP.push(
          data?.hompageScrollers?.edges[0]?.node?.homepage_scroller?.image5
            ?.mediaItemUrl
        )
      }

      setGallery(galleryHP)
    },
  })

  const scrollHeaderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: sliderHeaderSwiped,
    lazyLoad: true,
  }

  const updateAppraisalOverlay = setting => {
    if (overlayRight && overlayRight?.current) {
      if (setting) {
        overlayRight.current.style.backgroundColor = "rgba(0,98,169, 0)"
      } else {
        overlayRight.current.style.backgroundColor = "rgba(0,98,169, 0.8)"
      }
    }
  }

  const GetHeaderGallery = () => {
    let contentImg = gallery.map((item, index) => {
      return (
        <img alt="Brown and Kay" key={index} src={item} className="imgHousea" />
      )
    })

    return (
      <Slider ref={sliderRef} {...scrollHeaderSettings}>
        {contentImg}
      </Slider>
    )
  }

  const GetHeaderGalleryButtons = () => {
    let contentImg = gallery.map((item, index) => {
      let _opacity = 0.99
      if (index === 0) {
        _opacity = 0.5
      }

      return (
        <Grid key={index} className="quoteButtonHolder">
          <Grid
            className="quoteButton_selected quoteButton_white"
            style={{ opacity: _opacity }}
            ref={setRefButton("headerButton_" + index)}
            onClick={() => setHeaderPicToShow(index)}
            item
          ></Grid>
        </Grid>
      )
    })

    return contentImg
  }

  const setHeaderPicToShow = id => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(id)
    }

    for (let i = 0; i < gallery.length; i++) {
      let btn = getRefButton("headerButton_" + i)

      if (btn && btn?.current) {
        if (id == i) {
          btn.current.style.opacity = 0.5
        } else {
          btn.current.style.opacity = 0.99
        }
      }
    }
  }

  const setHousesToShow = num => {
    setHousePageCount(num * 4)

    for (let i = 0; i < 5; i++) {
      let btn = getRefButton("houseButton_" + i)

      if (btn && btn?.current) {
        if (num == i) {
          btn.current.style.opacity = 0.5
        } else {
          btn.current.style.opacity = 0.99
        }
      }
    }
  }

  const showQuote = id => {
    if (getRefQuote("quote_" + 0)) {
      for (let i = 0; i < 5; i++) {
        let quote = getRefQuote("quote_" + i)

        if (quote?.current) {
          quote.current.style.display = "none"
        }

        let btn = getRefButton("quoteButton_" + i)
        if (btn?.current) {
          btn.current.style.opacity = 1
        }
      }

      const quoteChosen = getRefQuote("quote_" + id)
      if (quoteChosen && quoteChosen?.current) {
        quoteChosen.current.style.display = "block"
      }

      const quoteButton = getRefButton("quoteButton_" + id)
      if (quoteButton && quoteButton?.current) {
        quoteButton.current.style.opacity = 0.5
      }
    }
  }

  const openLink = link => {
    window.open(link, "_blank")
  }

  const showItem = id => {
    window.open(`/property/?id=${id}`, "_blank")
  }

  const changeAppraisal = () => {
    setShowAppraisal(prevApp => !prevApp)
  }

  return (
    <Layout>
      <SEO title="Home" />

      <Grid container>
        <Grid container className="propertyGalleryHP">
          <Grid className="containerPropertyHP">
            <GetHeaderGallery />
          </Grid>

          <Grid container className="propertyGalleryHP_overlay">
            <Grid item className="propertyGalleryHP_overlay_left">
              <Grid item className="propertyGalleryHP_overlay_title">
                {galleryCopy}
              </Grid>

              <Grid item className="propertyGalleryHP_overlay_dots">
                <GetHeaderGalleryButtons />
              </Grid>
            </Grid>

            <Grid
              item
              ref={overlayRight}
              className="propertyGalleryHP_overlay_right"
            >
              <RequestApraisal
                updateAppraisalOverlay={updateAppraisalOverlay}
                closeButton={true}
                changeAppraisal={changeAppraisal}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* 2. ------------------------------- */}
        <Grid container className="grid1_hp1">
          <Grid item className="homepage2b_1"></Grid>

          <Grid item className="homepage1b_copy2">
            <h1>
              Discover our
              <br />
              properties for sale
            </h1>

            <p>
              At Brown & kay we sell all styles of property from studio flats
              and retirement apartments through to luxury residential homes. We
              cover all of Poole across to Hamworthy and Upton, Heckford Park,
              Canford Heath and Oakdale.
            </p>
            <p>
              {" "}
              The Avenue is where Poole and Bournemouth merge and from the
              beautiful beaches up through Westbourne village and into Talbot
              Woods and back to Bournemouth Centre and Southbourne , we are
              proud to offer our selection of homes.{" "}
            </p>
            <p>
              Our investment in dedicated property software systems enable us to
              match buyers and sellers quickly and efficiently and we
              communicate via text, e mail and of course by telephone. We keep
              in touch and up to date.
            </p>

            <Link to="/sales/">
              <div className="properties_for_sale">
                <Grid className="sales_button">
                  <div>PROPERTIES FOR SALE</div>
                  <div>'{">"}'</div>
                </Grid>
              </div>
            </Link>
          </Grid>
        </Grid>

        {/* 3. ------------------------------- */}

        <Grid container className="grid1_hp2">
          <Grid item className="homepage2b_2"></Grid>
          <Grid item className="homepage1b_copy2">
            <h1>Take a look at our properties for rent</h1>

            <p>
              At Brown & Kay we have a great selection of available properties
              to let across the area. Our landlords are professional, fair and
              experienced, so we’re sure we can find the right property for you
              at the right price.
            </p>
            <p>
              If you’re looking for a trustworthy agent with local knowledge and
              a solid reputation for great service, you’ve come to the right
              place. Search for your perfect home and request to book a viewing.
            </p>

            <Link to="/lettings/">
              <div className="properties_for_sale">
                <Grid className="sales_button">
                  <div>PROPERTIES FOR RENT</div>
                  <div>'{">"}'</div>
                </Grid>
              </div>
            </Link>
          </Grid>

          {/*</Grid>*/}
        </Grid>

        {/* 4. ------------------------------- */}

        <Grid container className="grid1_hp3">
          <Grid item xs={12} className="homepage3_header">
            Properties we think you might like
          </Grid>

          {houseData &&
            houseData.map((item, index) => {
              if (index >= housePageCount && index < housePageCount + 4) {
                return (
                  <Grid key={index} item className="propertyItemHolder">
                    <FeaturedProperty item={item} showItem={showItem} />
                  </Grid>
                )
              }
            })}

          <Grid container className="roundButtons">
            <Grid className="quoteButtonHolder">
              <Grid
                className="quoteButton_unselected quoteButton_blue"
                style={{ opacity: 0.5 }}
                ref={setRefButton("houseButton_" + 0)}
                onClick={() => setHousesToShow(0)}
                item
              ></Grid>
            </Grid>
            <Grid className="quoteButtonHolder">
              <Grid
                className="quoteButton_unselected quoteButton_blue"
                style={{ opacity: 0.99 }}
                ref={setRefButton("houseButton_" + 1)}
                onClick={() => setHousesToShow(1)}
                item
              ></Grid>
            </Grid>
            <Grid className="quoteButtonHolder">
              <Grid
                className="quoteButton_unselected quoteButton_blue"
                style={{ opacity: 0.99 }}
                ref={setRefButton("houseButton_" + 2)}
                onClick={() => setHousesToShow(2)}
                item
              ></Grid>
            </Grid>
            <Grid className="quoteButtonHolder">
              <Grid
                className="quoteButton_unselected quoteButton_blue"
                style={{ opacity: 0.99 }}
                ref={setRefButton("houseButton_" + 3)}
                onClick={() => setHousesToShow(3)}
                item
              ></Grid>
            </Grid>
            <Grid className="quoteButtonHolder">
              <Grid
                className="quoteButton_unselected quoteButton_blue"
                style={{ opacity: 0.99 }}
                ref={setRefButton("houseButton_" + 4)}
                onClick={() => setHousesToShow(4)}
                item
              ></Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* 5. ------------------------------- */}

        <Grid container className="hp4">
          <Grid container className="grid1_hp4">
            <h1>Introducing our team</h1>
          </Grid>

          <SliderTeam />
        </Grid>

        {/* 6. ------------------------------- */}
        <Grid container className="hp5">
          <Grid item xs={12} sm={4} className="hp5a">
            What our clients are saying…
          </Grid>
          <Grid item xs={12} sm={8} className="hp5b">
            <div className="quote_speechMark">"</div>
            <Query query={APOLLO_QUERY_QUOTES} onCompleted={() => showQuote(0)}>
              {({ data }) => {
                if (data) {
                  content = data?.cLIENTQUOTES?.edges?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        ref={setRefQuote("quote_" + index)}
                        className="client_quote"
                        dangerouslySetInnerHTML={{
                          __html: sanitizer(item?.node?.content),
                        }}
                      ></div>
                    )
                  })
                }

                return content
              }}
            </Query>

            <div className="quote_speechMark right">"</div>
          </Grid>

          <Grid container className="roundButtons">
            <Grid className="quoteButtonHolder">
              <Grid
                className="quoteButton_selected"
                ref={setRefButton("quoteButton_" + 0)}
                onClick={() => showQuote(0)}
                item
              ></Grid>
            </Grid>
            <Grid className="quoteButtonHolder">
              <Grid
                className="quoteButton_unselected"
                ref={setRefButton("quoteButton_" + 1)}
                onClick={() => showQuote(1)}
                item
              ></Grid>
            </Grid>
            <Grid className="quoteButtonHolder">
              <Grid
                className="quoteButton_unselected"
                ref={setRefButton("quoteButton_" + 2)}
                onClick={() => showQuote(2)}
                item
              ></Grid>
            </Grid>
            <Grid className="quoteButtonHolder">
              <Grid
                className="quoteButton_unselected"
                ref={setRefButton("quoteButton_" + 3)}
                onClick={() => showQuote(3)}
                item
              ></Grid>
            </Grid>
            <Grid className="quoteButtonHolder">
              <Grid
                className="quoteButton_unselected"
                ref={setRefButton("quoteButton_" + 4)}
                onClick={() => showQuote(4)}
                item
              ></Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* 7. ------------------------------- */}
        <Grid container className="hp6">
          <Grid
            onClick={() =>
              openLink(
                "https://www.onthemarket.com/agents/branch/brown-and-kay-westbourne/"
              )
            }
            item
            className="logos1"
            xs={6}
          ></Grid>
          <Grid
            onClick={() =>
              openLink(
                "https://www.rightmove.co.uk/estate-agents/agent/Brown-and-Kay/Westbourne-108385.html#ram"
              )
            }
            item
            className="logos2"
            xs={6}
          ></Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default IndexPage
